import React, { useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Button, IconButton, Typography, Toolbar, AppBar } from '@material-ui/core';

import moment from 'moment';
import { Grid } from '@material-ui/core';
import { ArrowForwardIos, ArrowBackIos, Today } from '@material-ui/icons';

const FullCalendarComponent = () => {
  const [currentEvents, setCurrentEvents] = useState([]);
  const calendarRef = useRef(null);

  const handleEventClick = (clickInfo) => {
    if (alert(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  };

  const handleDateSelect = (selectInfo) => {
    let title = prompt('Please enter a new title for your event');
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  const handlePrev = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
  };

  const handleNext = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
  };

  const handleToday = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.today();
  };

  const renderEventContent = (eventInfo) => (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );

  const events = [
    { title: 'Team Meeting', start: '2024-07-02T10:00:00', end: '2024-07-02T11:00:00' },
    { title: 'Project Review', start: '2024-07-04T14:00:00', end: '2024-07-04T15:00:00' },
    // Add more events as needed
  ];

  let eventGuid = 0;
  const createEventId = () => String(eventGuid++);

  return (
<Grid className="full-calendar-container" style={{ padding: 10, backgroundColor: '#E4E4E4' }}>
    <Grid style={{backgroundColor: '#ffffff'}}>
<AppBar position="static" color="default">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handlePrev} sx={{ mr: 2 }}>
            <ArrowForwardIos />
          </IconButton>
          <Button color="inherit" onClick={handleToday} startIcon={<Today />} sx={{ mr: 2 }}>
            Today
          </Button>
          <IconButton edge="start" color="inherit" onClick={handleNext} sx={{ mr: 2 }}>
            <ArrowForwardIos />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            {moment().format('MMMM YYYY')}
          </Typography>
        </Toolbar>
      </AppBar>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        weekends={false}
        events={events}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        select={handleDateSelect}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        allDaySlot={false} // Remove the all-day section
        headerToolbar={false} // Disable the default header
      />
    </Grid>
    </Grid>
  );
};

export default FullCalendarComponent;
