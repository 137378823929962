// src/hooks/useServiceWorkerMessages.js

import { useEffect, useState } from 'react';

export const useServiceWorkerMessages = () => {
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const handleServiceWorkerMessage = (event) => {
            setMessage(event.data);
        };

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
        }

        return () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
            }
        };
    }, []);

    return message;
};