import React, { useCallback, useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Button, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@material-ui/core';
import {
  Videocam as VideocamIcon,
  VideocamOff as VideocamOffIcon,
  Mic as MicIcon,
  MicOff as MicOffIcon,
  Chat as ChatIcon,
  ScreenShare as ScreenShareIcon,
  Stop as StopIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Info as InfoIcon,
  FileCopy as CopyIcon,
} from '@material-ui/icons';
import Timer from '../Room/Timer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  copyButton: {
    marginLeft: theme.spacing(1),
  },
  roomIdTextField: {
    width: '100%',
  },
}));

const BottomBar = ({
  clickChat,
  clickCameraDevice,
  goToBack,
  toggleCameraAudio,
  userVideoAudio,
  clickScreenSharing,
  screenShare,
  videoDevices,
  showVideoDevices,
  setShowVideoDevices,
  roomId,
}) => {
  const classes = useStyles();
  const [showVideoDevicesMenu, setShowVideoDevicesMenu] = useState(false);
  const [showRoomIdDialog, setShowRoomIdDialog] = useState(false);

  const handleToggle = useCallback(() => {
    setShowVideoDevicesMenu((state) => !state);
  }, []);

  const handleRoomIdDialogOpen = () => {
    setShowRoomIdDialog(true);
  };

  const handleRoomIdDialogClose = () => {
    setShowRoomIdDialog(false);
  };

  const handleCopyRoomId = () => {
    navigator.clipboard.writeText(roomId);
  };

  return (
    <AppBar position="fixed" color="#4ea1d3" style={{ top: 'auto', bottom: 0 }}>
      <Toolbar>
        <Tooltip title="Show Room Info" aria-label="show-room-info">
          <IconButton color="inherit" onClick={handleRoomIdDialogOpen}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Dialog open={showRoomIdDialog} onClose={handleRoomIdDialogClose} maxWidth="sm" fullWidth>
          <DialogTitle>Meeting Info</DialogTitle>
          <DialogContent>
            <div className={classes.dialogContent}>
              <TextField
                className={classes.roomIdTextField}
                label="Room ID"
                value={roomId}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
              <Tooltip title="Copy Room ID" aria-label="copy-room-id">
                <IconButton className={classes.copyButton} onClick={handleCopyRoomId}>
                  <CopyIcon />
                </IconButton>
              </Tooltip>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRoomIdDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <IconButton color="inherit" onClick={toggleCameraAudio} data-switch='video'>
          {userVideoAudio.video ? <VideocamIcon /> : <VideocamOffIcon />}
          <Typography variant="body2">Camera</Typography>
        </IconButton>
        {showVideoDevicesMenu && (
          <Menu
            anchorEl={document.querySelector('[data-switch="video"]')}
            keepMounted
            open={Boolean(showVideoDevicesMenu)}
            onClose={() => setShowVideoDevicesMenu(false)}
          >
            {videoDevices.map((device) => (
              <MenuItem key={device.deviceId} onClick={() => clickCameraDevice(device.deviceId)}>
                {device.label}
              </MenuItem>
            ))}
            <MenuItem disabled>Switch Camera</MenuItem>
          </Menu>
        )}
        <IconButton color="inherit" onClick={handleToggle}>
          <ArrowDropUpIcon />
        </IconButton>
        <IconButton color="inherit" onClick={toggleCameraAudio} data-switch='audio'>
          {userVideoAudio.audio ? <MicIcon /> : <MicOffIcon />}
          <Typography variant="body2">Audio</Typography>
        </IconButton>
        <div style={{ flexGrow: 1 }} />
        <Box ml={2}>
          <Timer />
        </Box>
        <div style={{ flexGrow: 1 }} />
        <IconButton color="inherit" onClick={clickChat}>
          <ChatIcon />
          <Typography variant="body2">Chat</Typography>
        </IconButton>
        <IconButton color="inherit" onClick={clickScreenSharing}>
          <ScreenShareIcon color={screenShare ? 'secondary' : 'inherit'} />
          <Typography variant="body2">Share Screen</Typography>
        </IconButton>
        <Button variant="contained" color="secondary" onClick={goToBack} startIcon={<StopIcon />}>
          Stop
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default BottomBar;
