  import React, { useState, useEffect, useRef } from 'react';
import Peer from 'simple-peer';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Grid, IconButton, Box } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import {useSocket} from '../../socket';
import BottomBar from '../BottomBar/BottomBar';
import Chat from '../Chat/Chat';
import VideoGrid from './VideoGrid';
import Timer from './Timer'; 

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  videoPlaceholder: {
    flex: 1,
    backgroundColor: '#2c2c2c',
    justifyContent: 'center',
    alignItems: 'center',
  },
  audioIndicator: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 15,
    padding: 5,
  },
  appBar: {
    background: theme.palette.primary.dark,
    boxShadow: 'none',
  },
  roomIdText: {
    flexGrow: 1,
    fontWeight: 600,
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    background: theme.palette.background.default,
  },
  videoContainer: {
    flex: 1,
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
}));

const Room = () => {
  const classes = useStyles();
  const currentUser = localStorage.getItem('userId');
  const currentUserName = localStorage.getItem('name');
  const [peers, setPeers] = useState([]);
  const [userVideoAudio, setUserVideoAudio] = useState({
    localUser: { video: true, audio: true }
  });
  const [displayChat, setDisplayChat] = useState(false);
  const [screenShare, setScreenShare] = useState(false);
  const [videoDevices, setVideoDevices] = useState([]);
  const [showVideoDevices, setShowVideoDevices] = useState(false);
    const peersRef = useRef([]);
    const userVideoRef = useRef();
    const screenTrackRef = useRef();
    const userStream = useRef();
    const location = useLocation();
    const history = useHistory();
    const { socket, isConnected, initializeSocket } = useSocket();
    const [time, setTime] = useState(0);
    const room = location.state || {};
    const searchParams = new URLSearchParams(location.search);
    const roomId = searchParams.get('meetingId') || room.roomName;


    const stopAllTracks = () => {
      if (userStream.current) {
        userStream.current.getTracks().forEach(track => track.stop());
        userStream.current = null;
      }
      peersRef.current.forEach(({ peer }) => peer.destroy());
      peersRef.current = []
      if (screenTrackRef.current) {
        screenTrackRef.current.stop();
        screenTrackRef.current = null;
      }
    
      if (userVideoRef.current && userVideoRef.current.srcObject) {
        userVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
        userVideoRef.current.srcObject = null;
      }
    };


  useEffect(() => {
    console.log('Room useEffect - socket state:', { isConnected, currentUser });
    if (!isConnected && currentUser) {
      console.log('Initializing socket from Room');
      initializeSocket(currentUser);
    }
  }, [isConnected, currentUser, initializeSocket]);


    useEffect(() => {
      console.log("roomId",peersRef.current)

      window.addEventListener('load', () => {
        const storedRoomId = localStorage.getItem('roomId');
        const storedUserId = localStorage.getItem('userId');
        const storedUserName = localStorage.getItem('userName');
      
        if (storedRoomId && storedUserId && storedUserName) {
          socket.emit('BE-reconnect-room', {
            roomId: storedRoomId,
            userId: storedUserId,
            userName: storedUserName
          });
        }
      });

      if (isConnected && socket) {
      if (!roomId) {
        history.replace('/');
        return; // Return early if roomId is invalid
      }
      const connectMediaStream = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          if (userVideoRef.current) {
            userVideoRef.current.srcObject = stream;
          }
          userStream.current = stream;
          console.log('Emitting BE-join-room event:', { roomId, userId: currentUser, userName: currentUser });

          socket.emit('BE-join-room', { roomId, userId: currentUser, userName: currentUserName });

        } catch (error) {
          console.error('Error accessing media devices:', error);
        }
      };
    
      connectMediaStream();
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const filtered = devices.filter((device) => device.kind === 'videoinput');
        setVideoDevices(filtered);
      });

      // Set Back Button Event
      window.addEventListener('popstate', goToBack);

      // Connect Camera & Mic
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          userVideoRef.current.srcObject = stream;
          userStream.current = stream;

          socket.on('FE-user-join', (users) => {
            console.log("received",users)
            const peers = [];

            users.forEach( user  => {
              if (!user.info) {
                console.log("Skipping user with null info:", user);
                return; // Skip this iteration if info is null
              }
              let { userId,userName, video, audio } = user.info;
              if (user.userId !== socket.id) {
                const peer = createPeer(user.userId, socket.id, userStream.current);
                
                peer.userName = userName;
                peer.peerID = user.userId;
                peer.userId = userId
                peersRef.current.push({
                  peerID: user.userId,
                  peer,
                  userName,
                   userId
                });
                peers.push(peer);
              
                setUserVideoAudio((preList) => ({
                  ...preList,
                  [user.userId]: { video, audio },
                }));
              
            }
            });
      console.log('setting peer')
            setPeers(peers);
            console.log("peers",peers)
          });
        

          socket.on('FE-receive-call', ({ signal, from, info }) => {
            let { userName,userId, video, audio } = info;
            const peerIdx = findPeer(from);
            if (!peerIdx) {
              console.log("accepted inside peer")

              const peer = addPeer(signal, from, stream);

              peer.userName = userName;
              peer.peerID =from;
              peer.userId = userId
              peersRef.current.push({
                peerID: from,
                peer,
                userName: userName,
                userId
              });
              setPeers((users) => [...users, peer]);
              setUserVideoAudio((preList) => ({
                ...preList,
                [from]: { video, audio },
              }));
            }
          });
console.log("seeing peer")
          socket.on('FE-call-accepted', ({ signal, answerId }) => {
            console.log("call initated")

            const peerIdx = findPeer(answerId);
            peerIdx.peer.signal(signal);
          });

          socket.on('FE-user-leave', ({ userId }) => {
            console.log("userId",userId)
console.log("peers",peers)
            setPeers((prevPeers) => {
              const updatedPeers = prevPeers.filter((peer) => peer.peerID !== userId);
              return updatedPeers;
            });
            peersRef.current = peersRef.current.filter((peer) => peer.peerID !== userId);
            setUserVideoAudio((prev) => {
              const { [userId]: _, ...newState } = prev;
              return newState;
            });
          });
      
          
        });

        socket.on('FE-toggle-camera', ({ userId, switchTarget }) => {
        console.log(userId)
          console.log(`Received toggle event for user ${userId}, target: ${switchTarget}`);
          
          try {
            console.log( peersRef.current)
            const peerIdx = findPeer(userId);
            if (!peerIdx) {
              console.warn(`Peer not found for userId: ${userId}`);
              return;
            }
            setUserVideoAudio((preList) => {
              const userState = preList[userId] || { video: true, audio: true };
              console.log(`Previous state for ${userId}:`, userState);
              
              const newState = {
                ...userState,
                [switchTarget]: !userState[switchTarget]
              };
              console.log(`New state for ${userId}:`, newState);
        
              return {
                ...preList,
                [userId]: newState
              };
            });
                      

          } catch (error) {
            console.error('Error handling toggle event:', error);
          }
        });
    
      return () => {
        try {
          // Discon
          console.log("cleanup")   
          // Stop all media tracks
          stopAllTracks();
         
          // Destroy all peers
          peersRef.current.forEach(({ peer }) => {
            peer.destroy();
          });
          peersRef.current = [];
       
          socket.off('FE-user-join');
          socket.off('FE-receive-call');
          socket.off('FE-call-accepted');
          socket.off('FE-user-leave');
          socket.off('FE-toggle-camera');
        } catch (error) {
          console.error('Cleanup error:', error);
        }
      };
    }else{
      console.log('Socket not connected or not available');

    }
   }, [isConnected, socket,location.search, room.roomName, history, currentUser, currentUserName, roomId]);

   function createPeer(userId, caller, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
      config: { 
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:openrelay.metered.ca:80' },
          {
            urls: 'turn:openrelay.metered.ca:80',
            username: 'openrelayproject',
            credential: 'openrelayproject'
          },
          {
            urls: 'turn:openrelay.metered.ca:443',
            username: 'openrelayproject',
            credential: 'openrelayproject'
          }
        ]
      }
    });
  
    peer.on('signal', (signal) => {
      console.log("call initated")
      socket.emit('BE-call-user', {
        userToCall: userId,
        from: caller,
        signal,
      });
    });
    
  
    return peer;
  }
  

  function addPeer(incomingSignal, callerId, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
      config: { 
          iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:openrelay.metered.ca:80' },
          {
            urls: 'turn:openrelay.metered.ca:80',
            username: 'openrelayproject',
            credential: 'openrelayproject'
          },
          {
            urls: 'turn:openrelay.metered.ca:443',
            username: 'openrelayproject',
            credential: 'openrelayproject'
          }
        ]
        
    }})
  
    peer.on('signal', (signal) => {
      socket.emit('BE-accept-call', { signal, to: callerId });
    });
  
    peer.on('stream', (remoteStream) => {
    //  console.log("Handling rmote")
    //   handleRemoteStream(callerId, remoteStream);
    });
  
    peer.signal(incomingSignal);
  
    return peer;
  }
  
  function handleRemoteStream(userId, stream) {
    // Update your state or directly set the stream to the video element
    setPeers(prevPeers => {
      return prevPeers.map(p => {
        if (p.peerID === userId) {
          return { ...p, stream: stream };
        }
        return p;
      });
    });
  }
    function findPeer(id) {
      return peersRef.current.find((p) => p.peerID === id);
    }


    const clickChat = (e) => {
      e.stopPropagation();
      setDisplayChat(!displayChat);
      
    };

    const goToBack = (e) => {
      e.preventDefault();
      peersRef.current.forEach(({ peer }) => {
        peer.destroy();
      });
      // Notify the server that the user is leaving the room
      socket.emit('BE-leave-room', { roomId, leaver: currentUser });
    
      // Stop all tracks
      stopAllTracks();
    
   
      peersRef.current = [];
    
      // Reset peers state
      setPeers([]);
    
    
      // Navigate back to the home screen
      history.replace('/');
    };

  const toggleCameraAudio = (e) => {
  const target = e.currentTarget.dataset.switch;

  console.log('Toggle target:', target);
  console.log('userVideoRef:', userVideoRef.current);
  console.log('userStream:', userStream.current);

  setUserVideoAudio((preList) => {
    let videoSwitch = preList['localUser'].video;
    let audioSwitch = preList['localUser'].audio;
  
    if (userStream.current) {
      if (target === 'video') {
        videoSwitch = !videoSwitch;
        userStream.current.getVideoTracks().forEach(track => {
          track.enabled = videoSwitch;
        });
      } else {
        audioSwitch = !audioSwitch;
        userStream.current.getAudioTracks().forEach(track => {
          track.enabled = audioSwitch;
        });
      }

      // Instead of immediately setting srcObject, schedule it for the next render
      setTimeout(() => {
        if (userVideoRef.current) {
          userVideoRef.current.srcObject = userStream.current;
        }
      }, 0);
    }

    return {
      ...preList,
      localUser: { video: videoSwitch, audio: audioSwitch },
    };
  });

  socket.emit('BE-toggle-camera-audio', {userId : socket.id , roomId, switchTarget: target });
};
    const clickScreenSharing = () => {
      if (!screenShare) {
        navigator.mediaDevices
          .getDisplayMedia({ cursor: true })
          .then((stream) => {
            const screenTrack = stream.getTracks()[0];

            peersRef.current.forEach(({ peer }) => {
              peer.replaceTrack(
                peer.streams[0]
                  .getTracks()
                  .find((track) => track.kind === 'video'),
                screenTrack,
                userStream.current
              );
            });

            screenTrack.onended = () => {
              peersRef.current.forEach(({ peer }) => {
                peer.replaceTrack(
                  screenTrack,
                  peer.streams[0]
                    .getTracks()
                    .find((track) => track.kind === 'video'),
                  userStream.current
                );
                userVideoRef.current.srcObject = userStream.current;
                setScreenShare(false);
              });
            };

            userVideoRef.current.srcObject = stream;
            screenTrackRef.current = screenTrack;
            setScreenShare(true);
          });
      } else {
        screenTrackRef.current.onended();
      }
    };
    useEffect(() => {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    
      return () => clearInterval(timer);
    }, []);
    const clickBackground = () => {
      if (!showVideoDevices) return;

      setShowVideoDevices(false);
    };
    const handleCloseChatBox = () => {
      setDisplayChat(false);
    };
    const constraints = {
      video: {
        width: { ideal: 640 },
        height: { ideal: 480 },
        facingMode: 'user',
        aspectRatio: { ideal: 1.7777777778 }
      },
      audio: true
    };
    const clickCameraDevice = (event) => {
      if (event && event.target && event.target.dataset && event.target.dataset.value) {
        const deviceId = event.target.dataset.value;
        const enabledAudio = userVideoRef.current.srcObject.getAudioTracks()[0].enabled;

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            const newStreamTrack = stream.getTracks().find((track) => track.kind === 'video');
            const oldStreamTrack = userStream.current
              .getTracks()
              .find((track) => track.kind === 'video');

            userStream.current.removeTrack(oldStreamTrack);
            userStream.current.addTrack(newStreamTrack);

            peersRef.current.forEach(({ peer }) => {
              peer.replaceTrack(
                oldStreamTrack,
                newStreamTrack,
                userStream.current
              );
            });
          }) .catch((error) => {
            console.error('Error accessing media devices:', error);
            // Handle the error (e.g., show a message to the user)
          });
      }
    };
   
    const copyToClipboard = () => {
      navigator.clipboard.writeText(roomId);
      alert('Room ID copied to clipboard');
    };

    return (
      <Grid container direction='row'>
        <Grid item xs={12}>
          <Grid container direction="column" className={classes.root} onClick={clickBackground}>
     
<Grid container direction='row' className={classes.videoContainer}>
 
  <VideoGrid
    peers={peers}
    userVideoRef={userVideoRef}
    currentUser={currentUserName}
    userVideoAudio={userVideoAudio}
  />
</Grid>
        
        <BottomBar
              clickScreenSharing={clickScreenSharing}
              clickChat={clickChat}
              clickCameraDevice={clickCameraDevice}
              goToBack={goToBack}
              toggleCameraAudio={toggleCameraAudio}
              userVideoAudio={userVideoAudio['localUser']}
              screenShare={screenShare}
              videoDevices={videoDevices}
              showVideoDevices={showVideoDevices}
              setShowVideoDevices={setShowVideoDevices}
              time={time}
roomId={roomId}
            />
      </Grid>
      </Grid>

            <Grid item xs={4}>
              <Chat roomId={roomId}  display={displayChat} onClose={handleCloseChatBox} />
            </Grid>
        
      </Grid>
    );
  };

  export default Room;
