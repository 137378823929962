import { useState, useEffect, useCallback } from 'react';
import { onMessage, getMessaging } from "firebase/messaging";
import { getApp } from "firebase/app";

export function useMessageListener() {
    const [callNotification, setIncomingCall] = useState(null);
    const [callAccepted, setCallAccepted] = useState(null);
    const [callRejected, setCallRejected] = useState(null);

    const messageHandler = useCallback((payload) => {
        console.log('Message received:', payload);
        if (payload.data && payload.data.type === 'INCOMING_CALL') {
            setIncomingCall({
                callerId: payload.data.callerId,
                callerName: payload.data.callerName,
                meetingId: payload.data.meetingId,
                caseId :payload.data.caseId
            });
        } else if (payload.data && payload.data.type === 'CALL_ACCEPTED') {
            setCallAccepted(payload.data.meetingId);
        } else if (payload.data && payload.data.type === 'CALL_REJECTED') {
            setCallRejected(payload.data.meetingId);
        }
    }, []);

    useEffect(() => {
        const app = getApp();
        const messaging = getMessaging(app);

        const unsubscribe = onMessage(messaging, messageHandler);

        return () => {
            if (unsubscribe && typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    }, [messageHandler]);

    const resetCallNotification = useCallback(() => setIncomingCall(null), []);
    const resetCallAccepted = useCallback(() => setCallAccepted(null), []);
    const resetCallRejected = useCallback(() => setCallRejected(null), []);

    return { 
        callNotification, 
        callAccepted, 
        callRejected, 
        resetCallNotification, 
        resetCallAccepted, 
        resetCallRejected 
    };
}