import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, Paper, makeStyles, List, ListItem,
  Avatar, Chip, IconButton, Tooltip, Tabs, Tab, AppBar,
  Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@material-ui/core';
import axios from 'axios';
import CallingScreen from '../UserCall/CallingScreen';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CallMadeIcon from '@material-ui/icons/CallMade';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FolderIcon from '@material-ui/icons/Folder';
import CallIcon from '@material-ui/icons/Call';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {  useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    padding: theme.spacing(3),
    backgroundColor: '#f0f2f5',
    position: 'relative',
    display: 'flex',
  },
  timeContainer: {
    flexShrink: 0,
    marginRight: theme.spacing(1),
  },
  callbackButton: {
    padding: theme.spacing(0.5),
  },
  callerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    minWidth: 0, // This allows the container to shrink below its content size
  },
  mainContent: {
    flexGrow: 1,
    marginRight: '270px', // Width of staff list + padding
  },
  paper: {
    padding: theme.spacing(3),
    height: '100%',
    borderRadius: 16,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  callsList: {
    maxHeight: 'calc(100vh - 250px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },

  caseIdChip: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
  },
  staffList: {
    position: 'fixed',
    top: theme.spacing(8),
    right: theme.spacing(2),
    width: '250px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
  },
  staffListItem: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
  },
  staffInfo: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  staffName: {
    fontWeight: 'bold',
  },
  staffRole: {
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },

  staffListContainer: {
    top: theme.spacing(8),
    marginTop :theme.spacing(15),
    right: theme.spacing(2),
    transition: 'transform 0.3s ease-in-out',
  },
  
  callItem: {
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(1),
    borderRadius: 8,
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  callerContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  callerInfo: {
    marginLeft: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  caseIdChip: {
    maxWidth: '100%',
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
  },
  timeAndCallbackContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  timeChip: {
    marginRight: theme.spacing(1),
  },
  callbackButton: {
    padding: theme.spacing(0.5),
  },

  statusIcon: {
    fontSize: 12,
    marginRight: theme.spacing(1),
  },
}));

const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

const Main = () => {
  const classes = useStyles();
  const [calls, setCalls] = useState([]);
  const [loggedInStaff, setLoggedInStaff] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [calling, setCalling] = useState(false);
  const [callee, setCallee] = useState(null);
const userId =localStorage.getItem('userId')
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
 const SETTLEMED_API_URL =' https://api.settlemed.in'
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [caseDetailsOpen, setCaseDetailsOpen] = useState(false);
  const [caseDetails, setCaseDetails] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchCallLogs = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_URL}/call-logs/company-log`);
        
        if (response.data.success) {
          console.log("process",response.data.callLogs)
          const sortedCallLogs = response.data.callLogs.sort((a, b) => new Date(b.calledAt) - new Date(a.calledAt));

          console.log("sortedCallLogs", sortedCallLogs);

          const processedCalls =sortedCallLogs.map(call => ({
            id: call.MMID,
            caller: call.caller.name ,
            callerId: call.caller ? call.caller.id : null,
            time: formatDateTime(call.calledAt),
            caseId: call.contextId, 
            type: call.type.toLowerCase(),
            participants: call.participants
          }));
          setCalls(processedCalls);
        } else {
          console.error('Failed to fetch call logs:', response.data);
        }
      } catch (error) {
        console.error('Error fetching call logs:', error);
      }
    };
  
    fetchCallLogs();

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_URL}/users/user-list`);
        setLoggedInStaff(response.data.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [REACT_APP_API_URL]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getCallIcon = (type) => {
    switch (type) {
      case 'incoming':
        return <CallReceivedIcon className={classes.callIcon} />;
      case 'outgoing':
        return <CallMadeIcon className={classes.callIcon} />;
      case 'missed':
        return <PhoneMissedIcon className={classes.callIcon} style={{ color: 'red' }} />;
      case 'responded':
        return <PhoneCallbackIcon className={classes.callIcon} style={{ color: 'green' }} />;
      default:
        return <CallIcon className={classes.callIcon} />;
    }
  };
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'online': return 'green';
      case 'offline': return 'red';
      case 'away': return 'orange';
      default: return 'grey';
    }
  };

  const handleCallUser = async (userId,isCallback,name) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/notifications/initiate-call`,
        { 
          userId, 
          callerId: localStorage.getItem('userId'),
          callerName: localStorage.getItem('name'),
          role:"Command Center" 
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
  
      if (response.status === 200) {
        const { meetingId } = response.data;
        console.log('Call initiated successfully. Meeting ID:', meetingId);
        console.log(loggedInStaff)
      if(isCallback){
        setCallee(name)
      }else{
        const calleeUser = loggedInStaff.find(staff => staff.id === userId);
        setCallee(calleeUser);
        console.log("call user",calleeUser)
      }
      
        setCalling(true);
      }
    } catch (error) {
      console.error('Error initiating call:', error);
    }
  };

  const handleCancelCall = () => {
    setCalling(false);
    setCallee(null);
  };
  const handleCallRejected = () => {
    showSnackbar('Call was not answered', 'warning');
    setCalling(false);
    setCallee(null);
  };
  const handleCallAccepted = (meetingId,caseId) => {
    console.log('Call accepted, joining room...');
    setCalling(false);
    setCallee(null);   
  history.push({ pathname: '/room', state: { roomName: meetingId } });
  };
const handleCallback = (call) => {
  console.log("caller Id",call.callerId)
    if (call.callerId) {
      handleCallUser(call.callerId,true,call.caller);
    } else {
      console.error('Unable to callback: Caller ID is missing');
    }
  };
  const filteredCalls = calls.filter(call => {
    if (tabValue === 0) return true; // All calls
    if (tabValue === 1) return call.type === 'incoming';
    if (tabValue === 2) return call.type === 'outgoing';
    if (tabValue === 3) return call.type === 'missed';
    if (tabValue === 4) return call.type === 'not responded';
    return false;
  });
  const handleCaseIdClick = async (caseId) => {
    try {
      console.log(caseId)
      const response = await axios.get(`${SETTLEMED_API_URL}/case-details/${caseId}`, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });      console.log("response",response)
      setCaseDetails(response.data.caseDetails);
      setCaseDetailsOpen(true);
      
    } catch (error) {
      console.error('Error fetching case details:', error);
      showSnackbar('Failed to fetch case details', 'error');
    }
  };

  const handleCloseCaseDetails = () => {
    setCaseDetailsOpen(false);
  };
  return (
    <Box className={classes.root}>
      {calling ? (
        <CallingScreen
        calleeName={callee || callee?.fullName}
        onCancel={handleCancelCall}
          onCallAccepted={handleCallAccepted}
          onCallRejected={handleCallRejected}
        />
      ) : (
        <>
          <Box className={classes.mainContent}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper elevation={3} className={classes.paper}>
                  <Typography component="h1" variant="h5" gutterBottom>
                    Company Call Logs
                  </Typography>
                  <AppBar position="static">
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="call logs tabs">
                      <Tab label="All" />
                      <Tab label="Incoming" />
                      <Tab label="Outgoing" />
                      <Tab label="Missed" />
                      <Tab label="Not Responed" />
                    </Tabs>
                  </AppBar>
                  <List className={classes.callsList}>
                    {filteredCalls.map((call) => (
                      <ListItem key={call.id} className={classes.callItem}>
                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                          <Grid item xs={4} className={classes.callerContainer}>
                            {getCallIcon(call.type)}
                            <Box className={classes.callerInfo}>
                              <Typography noWrap variant="body2">
                                {call.caller}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={4} className={classes.chipContainer}>
                            {call.caseId && (
                              <Chip
                                icon={<FolderIcon />}
                                label={call.caseId}
                                size="small"
                                className={classes.caseIdChip}
                                onClick={() => handleCaseIdClick(call.caseId)}
                              />
                            )}
                          </Grid>
                          <Grid item xs={4} className={classes.timeAndCallbackContainer}>
                            <Chip
                              icon={<AccessTimeIcon />}
                              label={call.time}
                              size="small"
                              className={classes.timeChip}
                            />
                            <Tooltip title="Call back">
                            <IconButton 
                          size="small" 
                          aria-label="call back" 
                          className={classes.callbackButton}
                          onClick={() =>  handleCallback(call)}
                        > <PhoneCallbackIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Box className={`${classes.staffListContainer}`}>
            <Paper className={classes.staffList}>
              <Typography variant="h6" gutterBottom>Online Staff</Typography>
              <List>
                {loggedInStaff.map((staff) => (
                  staff.id !== userId && (
                           <ListItem key={staff.id} className={classes.staffListItem}>
                    <FiberManualRecordIcon 
                      className={classes.statusIcon} 
                      style={{ color: getStatusColor(staff.status) }} 
                    />
                    <Avatar style={{ marginRight: '8px' }}>{staff.avatar}</Avatar>
                    <Box className={classes.staffInfo}>
                      <Typography className={classes.staffName}>{staff.fullName}</Typography>
                      <Typography className={classes.staffRole}>{staff.status}</Typography>
                    </Box>
                    <Tooltip title="Call staff">
                      <IconButton edge="end" aria-label="call staff" onClick={() => handleCallUser(staff.id)}>
                        <CallIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
           
          )
        ))}
              </List>
            </Paper>
          </Box>
          <Dialog
            open={caseDetailsOpen}
            onClose={handleCloseCaseDetails}
            aria-labelledby="case-details-dialog-title"
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogTitle id="case-details-dialog-title">Case Details</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {caseDetails && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Patient Information</Typography>
                    <Typography>Name: {caseDetails.patient.patientName}</Typography>
                    <Typography>Beneficiary No: {caseDetails.patient.beneficiaryNo}</Typography>
                    <Typography>Phone: {caseDetails.patient.phone}</Typography>
                    <Typography>Gender: {caseDetails.patient.gender}</Typography>
                    <Typography>Age: {caseDetails.patient.age}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Hospital Information</Typography>
                    <Typography>Name: {caseDetails.hospital.name}</Typography>
                    <Typography>Code: {caseDetails.hospital.code}</Typography>
                    <Typography>City: {caseDetails.hospital.city}</Typography>
                    <Typography>State: {caseDetails.hospital.state}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Case Information</Typography>
                    <Typography>Status: {caseDetails.status}</Typography>
                    <Typography>Created At: {new Date(caseDetails.createdAt).toLocaleString()}</Typography>
                    <Typography>Updated At: {new Date(caseDetails.updatedAt).toLocaleString()}</Typography>
                  </Grid>
                  {/* Add more details as needed */}
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCaseDetails} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      
    </Box>
  );
};

export default Main;