import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Room from './components/Room/Room';
import styled from 'styled-components';
import Login from './components/Login/Login';
import SidebarLayout from './components/Main/SidebarLayout';
import { requestForToken } from './components/firebaseConfig/firebase';
import IncomingCallNotification from './components/UserCall/IncomingCallNotification'
import axios from 'axios';
import { useMessageListener } from './components/firebaseConfig/OnMessageListner';
import { useServiceWorkerMessages } from './components/firebaseConfig/useServiceWorkerMessages';
import { SocketProvider } from './socket';

function App() {
  const [incomingCall, setIncomingCall] = useState(null);
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

  const { callNotification, callRejected,resetCallNotification, resetCallRejected } = useMessageListener();
  const message = useServiceWorkerMessages();
const userId = localStorage.getItem('userId')
  const isAuthenticated = () => {
    console.log(!!localStorage.getItem('token'))
    return !!localStorage.getItem('token');
  };

  // useEffect(() => {
  //   requestForToken()
  // }, []);

  const handleAcceptIncomingCall = async (callerId, meetingId,caseId) => {
    try {
      await axios.post(`${REACT_APP_API_URL}/notifications/accept-call`, {
       userId,
        callerId,
        meetingId,
      });
if(caseId){
const encodedJWT = encodeURIComponent(btoa(localStorage.getItem('token'))); 
const encodedID = encodeURIComponent(btoa(caseId));    

// Construct the URL with query parameters
const url = `https://admin.settlemed.in/Dashboard/EditCases/v2?token=${encodedJWT}&id=${encodedID}`;

// Open the new tab with the constructed URL
window.open(url, '_blank');
}
      joinRoom(meetingId);

      setIncomingCall(null);
    } catch (error) {
      console.error('Error accepting call:', error);
    }
  };

  const joinRoom = (meetingId) => {
    console.log('Joining room with meeting ID:', meetingId);
    window.location.href = `/room?meetingId=${meetingId}`;
  };

  const handleRejectIncomingCall = async (callerId, meetingId) => {
    try {
      setIncomingCall(null);

      await axios.post(`${REACT_APP_API_URL}/notifications/reject-call`, {
        callerId,
        meetingId,
        userId
      }); 

    } catch (error) {
      console.error('Error rejecting call:', error);
    }
  };

  useEffect(() => {
    if (callNotification) {
      setIncomingCall(callNotification)
      resetCallNotification();  
    } 
     if (callRejected) {
      setIncomingCall(null)
      resetCallRejected();
    }
  }, [callNotification, callRejected]);

  // useEffect(() => {
  //   function handleMessage(event) {
  //     console.log('app.js', event.data);

  //     if (event.data.action === 'CALL_REJECTED') {
  //       handleRejectIncomingCall(event.data.data.callerId, event.data.data.meetingId);
  //     } else if (event.data.action === 'CALL_ACCEPTED') {
  //       handleAcceptIncomingCall(event.data.data.callerId, event.data.data.meetingId);
  //     } else if (event.data.action === 'INCOMING_CALL') {
  //       // This is a normal notification, show the UI
  //       setIncomingCall(event.data.data);
  //     }
  //   }

  //   navigator.serviceWorker.addEventListener('message', handleMessage);

  //   return () => {
  //     navigator.serviceWorker.removeEventListener('message', handleMessage);
  //   };
  // }, []);

  useEffect(() => {
    if (message && message.action === 'ACCEPT') {

      handleAcceptIncomingCall(message.data.callerId, message.data.meetingId)
    }
    else if (message && message.action === 'REJECT') {
      handleRejectIncomingCall(message.data.callerId, message.data.meetingId)
    }
  }, [message]);

  return (
    <SocketProvider>

    <BrowserRouter>
      <AppContainer>
        <Switch>
          <Route exact path="/" render={() => (
            isAuthenticated() ? <Redirect to="/main" /> : <Login />
          )} />

          <Route path="/main" render={() => (
            isAuthenticated() ? <SidebarLayout /> : <Redirect to="/" />
          )} />
          <Route path="/schedulecalls" render={() => (
            isAuthenticated() ? <SidebarLayout /> : <Redirect to="/" />
          )} />
          <Route exact path="/room" render={() => (
            isAuthenticated() ? <Room /> : <Redirect to="/" />
          )} />
          <Route path="/callhistory" render={() => (
            isAuthenticated() ? <SidebarLayout /> : <Redirect to="/" />
          )} />
            <Route path="/contacts" render={() => (
            isAuthenticated() ? <SidebarLayout /> : <Redirect to="/" />
          )} />
        </Switch>
        {incomingCall && (
          <IncomingCallNotification
            open={true}
            caller={incomingCall.callerName}
            onAccept={() => handleAcceptIncomingCall(incomingCall.callerId, incomingCall.meetingId,incomingCall.caseId)}
            onReject={() => handleRejectIncomingCall(incomingCall.callerId, incomingCall.meetingId)}
          />
        )}
      </AppContainer>
    </BrowserRouter>
    </SocketProvider>
  );
}

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
  background-color: #454552;
  text-align: center;
`;

export default App;