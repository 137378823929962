import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, Box, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import call_ringtone from '../../assests/ringtone/call_ringtone.mp3';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 16,
      padding: theme.spacing(2),
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
  },
  callButton: {
    borderRadius: '50%',
    width: 64,
    height: 64,
    minWidth: 64,
  },
  acceptButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  rejectButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const IncomingCallNotification = ({ open, caller, callerAvatar, onAccept, onReject }) => {
  const classes = useStyles();
  const audioRef = useRef(null);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    audioRef.current = new Audio(call_ringtone);
    audioRef.current.loop = true;

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  useEffect(() => {
    if (open && audioRef.current) {
      audioRef.current.play().catch(() => {});
      const timer = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      return () => clearInterval(timer);
    } else if (!open && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setCountdown(30);
    }
  }, [open]);

  useEffect(() => {
    if (countdown === 0) {
      handleReject();
    }
  }, [countdown]);

  const handleAccept = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    onAccept();
  };

  const handleReject = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    onReject();
  };

  return (
    <Dialog open={open} className={classes.dialog}>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
          <Avatar
            src={callerAvatar}
            alt={caller}
            style={{ width: 80, height: 80, marginBottom: 16 }}
          />
          <Typography variant="h5" gutterBottom>
            {caller}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Incoming call
          </Typography>
          <Typography variant="caption" color="textSecondary" style={{ marginTop: 8 }}>
            Call will be rejected in {countdown} seconds
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginTop: 16 }}>
        <Button
          onClick={handleReject}
          className={`${classes.callButton} ${classes.rejectButton}`}
        >
          <CallEndIcon />
        </Button>
        <Box style={{ width: 48 }} />
        <Button
          onClick={handleAccept}
          className={`${classes.callButton} ${classes.acceptButton}`}
          autoFocus
        >
          <CallIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IncomingCallNotification;