import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { 
  Button, TextField, Typography, Container, Paper, 
  makeStyles, InputAdornment, IconButton
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Email, Lock, Visibility, VisibilityOff } from '@material-ui/icons';
import { useSocket } from '../../socket';
import { requestForToken } from '../firebaseConfig/firebase';
// DEV
// const SETTLEMED_API_URL = "https://apidev.settlemed.in";
// prod
const SETTLEMED_API_URL = "https://api.settlemed.in";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    borderRadius: 16,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
    borderRadius: 25,
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 25,
      backgroundColor: 'white',
    },
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
      '-webkit-text-fill-color': 'inherit !important',
      'transition': 'background-color 5000s ease-in-out 0s',
    },
  },
  errorAlert: {
    marginTop: theme.spacing(2),
    borderRadius: 25,
  },
}));

const Login = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const { initializeSocket } = useSocket();
  const [fcmToken, setFcmToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('FCM');
    if (storedToken) {
      setFcmToken(storedToken);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      const response = await axios.post(
        `${SETTLEMED_API_URL}/login`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(response.data.error || 'Login failed');
      }
  
      const { session, user } = response.data;
      localStorage.setItem('token', session.token);
      localStorage.setItem('userId', user.id);
      localStorage.setItem('name', user.fullName);
      initializeSocket(user.id);
      
      await requestAndUpdateToken();
    } catch (error) {
      console.error('Error during login:', error);
      setError(error.message || 'An error occurred during login');
    }
  };

  const requestAndUpdateToken = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        let token = fcmToken;
        if (!token) {
          console.log("Requesting new FCM token");
          token = await requestForToken();
          if (token) {
            setFcmToken(token);
            localStorage.setItem('FCM', token);
          } else {
            console.warn('Failed to retrieve FCM token');
          }
        }
        
        if (token) {
          console.log("Updating FCM token on the server");
          await updateFirebaseId(token);
        }
      } else {
        console.warn('Notification permission denied');
      }
      console.log("Navigating to main page");
      history.push('/main');
    } catch (error) {
      console.error('Error in requestAndUpdateToken:', error);
      history.push('/main');
    }
  };

  const updateFirebaseId = async (token) => {
    try {
      await axios.patch(
        `${SETTLEMED_API_URL}/update-firebaseId`,
        { firebaseId: token },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token')
          },
        }
      );
    } catch (error) {
      console.error('Error updating Firebase ID:', error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} className={classes.paper}>
        <Typography component="h1" variant="h4" gutterBottom>
          Welcome Back
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Please login to your account
        </Typography>
        <form className={classes.form} onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email color="action" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock color="action" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Alert severity="error" className={classes.errorAlert}>
              {error}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
