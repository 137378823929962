import React, { createContext, useState, useContext, useEffect, useRef, useCallback } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const initializeSocket = useCallback((userId) => {
    console.log('Initializing socket for user:', userId);
    if (socketRef.current) {
      console.log('Socket already exists, returning existing socket');
      return socketRef.current;
    }

    const url = process.env.REACT_APP_API_URL;
    console.log('Connecting to URL:', url);

    try {
      const newSocket = io(url, {
        query: { userId },
        autoConnect: false,
        forceNew: true,
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 20000,
      });

      newSocket.on('connect', () => {
        console.log('Socket connected successfully');
        setIsConnected(true);
        setIsInitialized(true);
        startHeartbeat(userId, newSocket);
      });

      newSocket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
        setIsConnected(false);
        setIsInitialized(true);
      });

      newSocket.on('disconnect', (reason) => {
        console.log('Socket disconnected:', reason);
        setIsConnected(false);
        stopHeartbeat();
      });

      socketRef.current = newSocket;
      newSocket.connect();
      console.log('New socket created and stored in ref');
      return newSocket;
    } catch (error) {
      console.error('Error creating socket:', error);
      setIsInitialized(true);
      return null;
    }
  }, []);


  const closeSocket = useCallback(() => {
    console.log('Closing socket');
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
      setIsConnected(false);
    }
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId && !isConnected && !socketRef.current) {
      console.log('Initializing socket on mount or refresh');
      initializeSocket(userId);
    }
  }, [isConnected, initializeSocket]);

  
  const contextValue = {
    socket: socketRef.current,
    isConnected,
    isInitialized,
    initializeSocket,
    closeSocket
  };

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

function startHeartbeat(userId, socket) {
  const heartbeatInterval = setInterval(() => {
    if (socket.connected) {
      socket.emit('heartbeat', { userId });
    }
  }, 5000);

  socket.on('disconnect', () => {
    clearInterval(heartbeatInterval);
  });
}

function stopHeartbeat() {
  // Implement if needed
}