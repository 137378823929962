import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';

const Timer = () => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <Box display="flex" alignItems="center">
      <AccessTime style={{ marginRight: '8px', fontSize: '24px' }} />
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {formatTime(time)}
      </Typography>
    </Box>
  );
};

export default Timer;
